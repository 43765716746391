/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  SelectProps,
  Typography,
} from '@material-ui/core';
import LimboToolTip from 'src/components/molecules/limbo-tool-tip';
import { withFormikField } from 'src/utils/formik';
import TruncateTextWithTooltip from 'src/components/molecules/limbo-truncate-text-with-tooltip';

interface LimboSelectProps extends SelectProps {
  label: string;
  data: Array<{ value: string; label: string; disabled?: boolean }>;
  errorText?: string;
  tooltip?: string;
  required?: boolean;
  disabled?: boolean;
}

export const LimboSelect: React.FC<LimboSelectProps> = ({
  label,
  data,
  errorText = '',
  tooltip = '',
  required = false,
  disabled = false,
  ...props
}) => (
  <FormControl
    required={required}
    variant="outlined"
    fullWidth
    error={!!errorText}
    disabled={disabled}
  >
    {tooltip && <LimboToolTip tooltip={<Typography>{tooltip}</Typography>} />}
    <InputLabel>{label}</InputLabel>
    <Select label={label} {...props}>
      {data?.map((item) => (
        <MenuItem key={item.value} value={item.value} disabled={item.disabled}>
          <TruncateTextWithTooltip tooltip={item.label}>
            <Typography variant="inherit">{item.label}</Typography>
          </TruncateTextWithTooltip>
        </MenuItem>
      ))}
    </Select>
    {errorText && (
      <FormHelperText style={{ color: 'red' }}>{errorText}</FormHelperText>
    )}
  </FormControl>
);

const LimboFormikSelect = withFormikField(LimboSelect);

export default LimboFormikSelect;
