import loadable from 'loadable-components';
import { LoadingComponent } from './components/atoms/loading-component';

export const NeuerZaehler = loadable(
  () =>
    import(
      './modules/Zaehlpunkt/page.wizard.neuer-zaehlpunkt' /* webpackChunkName: "detail-view-neuer-zaehlpunkt" */
    ),
  {
    LoadingComponent,
  }
);
export const ZaehlerstandErfassen = loadable(
  () =>
    import(
      './modules/Zaehlpunkt/page.wizard.zaehlerstand-erfassen' /* webpackChunkName: "detail-view-zaehlerstand-erfassen" */
    ),
  {
    LoadingComponent,
  }
);
export const LiegenschaftDetailView = loadable(
  () =>
    import(
      './modules/Liegenschaften/page.liegenschaft' /* webpackChunkName: "detail-view-liegenschaft" */
    ),
  {
    LoadingComponent,
  }
);
export const NeueLiegenschaft = loadable(
  () =>
    import(
      './modules/Liegenschaften/page.wizard.neue-liegenschaft' /* webpackChunkName: "detail-view-neue-liegenschaft" */
    ),
  {
    LoadingComponent,
  }
);

export const ListViewZaehlpunkte = loadable(
  () =>
    import(
      './modules/Zaehlpunkt/page.zaehlpunkte' /* webpackChunkName: "list-view-zaehlpunkt" */
    ),
  {
    LoadingComponent,
  }
);

export const DetailViewQRCodeResolver = loadable(
  () =>
    import(
      './modules/QRCodes/page.qrcodes-short' /* webpackChunkName: "detail-view-qrcode-resolver" */
    ),
  {
    LoadingComponent,
  }
);

export const ListViewQRCodes = loadable(
  () =>
    import(
      './modules/QRCodes/page.qrcodes' /* webpackChunkName: "list-view-zaehlpunkt-qrcodes" */
    ),
  {
    LoadingComponent,
  }
);

export const ListViewRechnungen = loadable(
  () =>
    import(
      './modules/Rechnungen/page.rechnungen' /* webpackChunkName: "list-view-rechnungen" */
    ),
  {
    LoadingComponent,
  }
);
export const DetailViewKommune = loadable(
  () =>
    import(
      './modules/Kommune/page.meine-kommune' /* webpackChunkName: "detail-view-meine-kommune" */
    ),
  {
    LoadingComponent,
  }
);
export const DetailViewKommuneLite = loadable(
  () =>
    import(
      './modules/Kommune/page.meine-kommune-lite' /* webpackChunkName: "detail-view-meine-kommune" */
    ),
  {
    LoadingComponent,
  }
);
export const DetailViewVerband = loadable(
  () =>
    import(
      './modules/Kommune/page.mein-verband' /* webpackChunkName: "detail-view-meine-kommune" */
    ),
  {
    LoadingComponent,
  }
);
export const DetailViewEinstellungen = loadable(
  () =>
    import(
      './modules/Einstellungen/page.einstellungen' /* webpackChunkName: "detail-view-einstellungen" */
    ),
  {
    LoadingComponent,
  }
);
export const ListViewDateneingabe = loadable(
  () =>
    import(
      './modules/Dateneingabe/page.dateneingabe' /* webpackChunkName: "list-view-dateneingabe" */
    ),
  {
    LoadingComponent,
  }
);

export const ListViewEnergieberichte = loadable(
  () =>
    import(
      './modules/Energieberichte/page.energieberichte' /* webpackChunkName: "list-view-energieberichte" */
    ),
  {
    LoadingComponent,
  }
);

export const ListViewEnergieberichteAdmin = loadable(
  () =>
    import(
      './modules/Energieberichte/page.energieberichte-admin' /* webpackChunkName: "list-view-energieberichte-admin" */
    ),
  {
    LoadingComponent,
  }
);

export const ListViewEnergieberichteAdminKommune = loadable(
  () =>
    import(
      './modules/Energieberichte/page.energieberichte-admin-kommune' /* webpackChunkName: "list-view-energieberichte-admin-kommune" */
    ),
  {
    LoadingComponent,
  }
);

export const ListViewEnergieberichteAdminVerband = loadable(
  () =>
    import(
      './modules/Energieberichte/page.energieberichte-admin-verband' /* webpackChunkName: "list-view-energieberichte-admin-verband" */
    ),
  {
    LoadingComponent,
  }
);

export const ListViewEnergieberichteMissingData = loadable(
  () =>
    import(
      './modules/Energieberichte/page.energieberichte-missing-data' /* webpackChunkName: "list-view-energieberichte-missing-data" */
    ),
  { LoadingComponent }
);

export const FehlendeZaehlerstaendeErfassen = loadable(
  () =>
    import(
      './modules/Energieberichte/page.energieberichte-fehlende-daten-erfassen' /* webpackChunkName: "fehlende-zaehlerstaende-erfassen" */
    ),
  { LoadingComponent }
);

export const FehlendeRechnungenErfassen = loadable(
  () =>
    import(
      './modules/Energieberichte/page.energieberichte-fehlende-rechnungen-erfassen' /* webpackChunkName: "fehlende-rechnungen-erfassen" */
    ),
  { LoadingComponent }
);

export const FehlendeZaehlpunkteErfassen = loadable(
  () =>
    import(
      './modules/Energieberichte/page.energieberichte-fehlende-zaehlpunkte-erfassen' /* webpackChunkName: "fehlende-zaehlpunkte-erfassen" */
    ),
  { LoadingComponent }
);

export const DetailViewNotFound = loadable(
  () =>
    import(
      './modules/Config/view-not-found' /* webpackChunkName: "detail-view-not-found" */
    ),
  {
    LoadingComponent,
  }
);

export const KipRegistrierung = loadable(
  () => import('./modules/KipRegistrierung/page.kipregistrierung'),
  {
    LoadingComponent,
  }
);

export const KipAuthCheck = loadable(
  () => import('./modules/KipRegistrierung/page.authcheck'),
  {
    LoadingComponent,
  }
);

export const Login = loadable(() => import('./modules/Login/page.login'), {
  LoadingComponent,
});

export const ListViewLiegenschaften = loadable(
  () =>
    import(
      './modules/Liegenschaften/page.liegenschaften' /* webpackChunkName: "list-view-liegenschaften" */
    ),
  {
    LoadingComponent,
  }
);
export const DetailViewNeueMassnahme = loadable(
  () =>
    import(
      './modules/Massnahmen/page.wizard.neue-massnahme' /* webpackChunkName: "detail-view-neue-massnahme" */
    ),
  {
    LoadingComponent,
  }
);
export const DetailViewNeueRechnung = loadable(
  () =>
    import(
      './modules/Rechnungen/page.wizard.neue-rechnung' /* webpackChunkName: "detail-view-neue-rechnung" */
    ),
  {
    LoadingComponent,
  }
);
export const DetailHelp = loadable(
  () =>
    import(
      './modules/Help/page.hilfe' /* webpackChunkName: "list-view-hilfebereich" */
    ),
  {
    LoadingComponent,
  }
);

export const DetailHelpAdmin = loadable(
  () =>
    import(
      './modules/Help/page.hilfe-admin' /* webpackChunkName: "list-view-hilfebereich-admin" */
    ),
  {
    LoadingComponent,
  }
);

export const DetailHelpAdminKommune = loadable(
  () =>
    import(
      './modules/Help/page.hilfe-admin-kommune' /* webpackChunkName: "list-view-hilfebereich-admin-kommune" */
    ),
  {
    LoadingComponent,
  }
);

export const DetailHelpAdminVerband = loadable(
  () =>
    import(
      './modules/Help/page.hilfe-admin-verband' /* webpackChunkName: "list-view-hilfebereich-admin-verband" */
    ),
  {
    LoadingComponent,
  }
);
export const NeuerKunde = loadable(
  () =>
    import(
      './modules/Kunde/page.neuer-kunde' /* webpackChunkName: "list-view-neuer-mandant" */
    ),
  {
    LoadingComponent,
  }
);
export const NeuenKundenKommuneAnlegen = loadable(
  () =>
    import(
      './modules/Kunde/page.wizard.neuer-kunde-kommune' /* webpackChunkName: "list-view-neuer-mandant" */
    ),
  {
    LoadingComponent,
  }
);
export const NeuenKundenVerbandAnlegen = loadable(
  () =>
    import(
      './modules/Kunde/page.wizard.neuer-kunde-verband' /* webpackChunkName: "list-view-neuer-mandant" */
    ),
  {
    LoadingComponent,
  }
);
export const Datenimport = loadable(
  () =>
    import(
      './modules/Kunde/page.wizard.datenimport' /* webpackChunkName: "list-view-neuer-mandant" */
    ),
  {
    LoadingComponent,
  }
);
