export enum Status {
  inaktiv,
  aktiv,
  // ersetzt,
}

export interface ZaehlpunktLiegenschaft {
  prozentAnteil: number;
  id: string;
}

export interface Zaehler {
  ersetztAm?: string;
  zaehlernummer: string;
  wandlerfaktor?: number;
}

interface Emissionsfaktor {
  gueltigBis?: string;
  emissionsFaktorTyp: number;
  emissionsfaktor?: number;
}

export interface Zaehlpunkt {
  status: Boolean;
  zaehlpunktTyp: number;
  zaehlernummern: Zaehler[];
  emissionsfaktoren?: Emissionsfaktor[];
  zaehlerRelationsTyp: number;
  parent: string;
  liveMessung: number;
  liegenschaften: ZaehlpunktLiegenschaft[];
  name: string;
  children: any[];
  erfassungsTyp: number;
  heizungsartRaumwaerme: number;
  id: string;
}

export interface BackendZaehlpunkt extends Omit<Zaehlpunkt, 'status'> {
  /**
   * @deprecated Use `zaehlernummern` instead.
   */
  zaehlernummer: string;
  status?: Status;
}

export interface Zaehlerstand {
  wert: number;
  zeitpunkt: string;
  zaehlernummer: string;
  erfasstDurch: string;
  nulldurchgang: boolean;
  erfasstAm: string;
  id: string;
  quelle: string;
  zaehlpunkt_id: string;
}
