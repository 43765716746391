import { EnergieSparte } from '../Config/hooks';

export const ERROR_INCREASING_NUMBER =
  'Der Zählerstand muss größer oder gleich dem vorhergehende sein';
export const ERROR_DECREASING_NUMBER = 'Der folgende Zählerstand ist kleiner';
export const ERROR_UNIQUE_ZAEHLER_NUMMER =
  'zählernummern müssen eindeutig sein';
export const ERROR_UNIQUE_ZAEHLER_ERSETZT_AM = 'Datum muss eindeutig sein';
export const ERROR_INCREASING_ZAEHLER_ERSETZT_AM =
  'Datum muss aufsteigend sein';
export const ERROR_FUTURE_ZAEHLER_ERSETZT_AM =
  'Datum darf nicht in der Zukunft liegen';
export const ERROR_VALUES_AFTER_ZAEHLER_ERSETZT_AM =
  'es existieren bereits Werte nach diesem Datum';

export const LABEL_STROM = 'Stromzähler';
export const LABEL_GAS = 'Gaszähler';
export const LABEL_WASSER = 'Wasserzähler';
export const LABEL_WAERME = 'Sonstige Wärmebezugsquelle';
export const LABEL_EINSPEISER = 'Strom - Einspeisung';
export const LABEL_PRODUKTION = 'Strom - Produktion';

export const ZAEHLER_STROM = { value: EnergieSparte.Strom, label: LABEL_STROM };
export const ZAEHLER_GAS = { value: EnergieSparte.Gas, label: LABEL_GAS };
export const ZAEHLER_WASSER = {
  value: EnergieSparte.Wasser,
  label: LABEL_WASSER,
};
export const ZAEHLER_EINSPEISUNG = {
  value: EnergieSparte.StromEinspeisung,
  label: LABEL_EINSPEISER,
};
export const ZAEHLER_SONSTIGE_WAERME_BEZUGSQUELLE = {
  value: EnergieSparte.Waerme,
  label: LABEL_WAERME,
};
export const ZAEHLER_PRODUKTION = {
  value: EnergieSparte.StromProduktion,
  label: LABEL_PRODUKTION,
};

export const TYPES_ERFASSUNG = {
  Zählerstand: 'zaehlerstand',
  Menge: 'menge',
};

export const ZAEHLPUNKT_TYPE_UNIT_MAP = {
  [EnergieSparte.Strom]: 'kWh',
  [EnergieSparte.Gas]: 'm³',
  [EnergieSparte.Wasser]: 'm³',
  [EnergieSparte.Waerme]: 'kWh',
  [EnergieSparte.StromEinspeisung]: 'kWh',
  [EnergieSparte.StromProduktion]: 'kWh',
};
