import { EnergieSparte } from '../Config/hooks';
import { ZAEHLPUNKT_TYPE_UNIT_MAP } from './constants';
import { saveAs } from 'file-saver';
import { Status } from './types';
import {
  ZAEHLER_ERSETZT_AM,
  ZAEHLER_FINALER_ZAEHLERSTAND,
  ZAEHLER_NUMMER,
  ZAEHLER_NUMMERN,
  ZAEHLER_WANDLERFAKTOR,
} from './components/forms/fields';
import {
  ZAEHLERSTAND_MESSWERT,
  ZAEHLERSTAND_ZEITPUNKT,
} from './components/zaehler-werte-erfassen/zaehlerstaende-erfassen-form/form-fields';
import { getDateWithHoursAndMinutesSetToZero } from './components/zaehler-werte-erfassen/zaehlerstaende-erfassen-form/helper';

interface GetUnitByType {
  zaehlpunktTyp: EnergieSparte;
}

export const getUnitByType = ({ zaehlpunktTyp }: GetUnitByType) =>
  ZAEHLPUNKT_TYPE_UNIT_MAP[zaehlpunktTyp];

export const convertToExcelFile = (data: string, fileName: string) => {
  const decodedData = Buffer.from(data, 'base64');
  const blob = new Blob([decodedData], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
  });
  saveAs(blob, `${fileName}.xlsx`);
};

export const getStatus = (status?: Status) =>
  status === undefined ? true : status === Status.aktiv;

export const getIsRLMZaehler = (liveMessung: number) => liveMessung === 1;

export const getIsInactiveZaehler = (status: boolean) =>
  status === !!Status.inaktiv;

interface GetFinalerZaehlerstandFromFormValues {
  id: string;
  zaehlernummern: any[];
}
export const getFinalerZaehlerstandFromFormValues = (
  values: GetFinalerZaehlerstandFromFormValues
) => {
  // NOTE: only replaced zaehler need to be considered
  const zaehlernummern = values[ZAEHLER_NUMMERN.name].filter(
    (zn) =>
      !!zn[ZAEHLER_ERSETZT_AM.fieldName] &&
      !!zn[ZAEHLER_FINALER_ZAEHLERSTAND.fieldName]
  );

  return zaehlernummern.reduce((acc, zaehler) => {
    const wert = zaehler[ZAEHLER_FINALER_ZAEHLERSTAND.fieldName];

    const zeitpunkt = getDateWithHoursAndMinutesSetToZero(
      new Date(zaehler[ZAEHLER_ERSETZT_AM.fieldName])
    );

    return [
      ...acc,
      {
        id: values.id,
        [ZAEHLERSTAND_MESSWERT.fieldName]: wert,
        [ZAEHLERSTAND_ZEITPUNKT.fieldName]: zeitpunkt,
      },
    ];
  }, []);
};

export const filterAndNormalizeZaehlerAttributes = (zaehlernummern: any[]) => {
  const ATTRIBUTES = [
    ZAEHLER_NUMMER.fieldName,
    ZAEHLER_ERSETZT_AM.fieldName,
    ZAEHLER_WANDLERFAKTOR.fieldName,
  ];
  return zaehlernummern.map((zn) => {
    return Object.keys(zn)
      .filter((key) => ATTRIBUTES.indexOf(key) >= 0)
      .reduce((acc, key) => {
        if (key === ZAEHLER_ERSETZT_AM.fieldName) {
          const ersetztAm = Date.parse(zn[key]);
          return {
            ...acc,
            [key]: isNaN(ersetztAm)
              ? undefined
              : getDateWithHoursAndMinutesSetToZero(new Date(zn[key])),
          };
        } else {
          return { ...acc, [key]: zn[key] };
        }
      }, {});
  });
};
